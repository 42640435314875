<template>
  <el-collapse class="info-carriers-quote-collapse" v-model="openedTabs">
    <el-collapse-item
      :title="$t('myLoads.myLoadsDetail.carrierQuotesDetails')"
      name="CarriersQuotes"
    >
      <div class="carriers-quotes-container">
        <div class="carriers-quotes-content">
          <TableHeaderContainer :fields="dataFields" :styles="styleObject" class="table-header" />
          <div v-if="data.length > 0" class="table">
            <TableRowContent :dataCarriers="data" :lineItems="lineItems" />
          </div>
          <div v-else class="carriers-not-found">
            <i class="ion ion-file-tray-outline carriers-not-found__icon"></i>
            <p class="carriers-not-found__text">No data were found.</p>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from "vuex";
import TableHeaderContainer from "@/components/TableHeaderContainer.vue";
import TableRowContent from "./TableRowContentCarriersQuote.vue";

export default {
  name: "InfoCarriersQuote",
  components: {
    TableHeaderContainer,
    TableRowContent,
  },
  data() {
    return {
      openedTabs: [],
      dataFields: [
        { key: "carrier_quoted_name", label: "carrierQuotedName" },
        { key: "service_class", label: "serviceClass" },
        { key: "service_class", label: "transitTime" },
        { key: "expiration_date", label: "expirationDate" },
        { key: "service_class", label: "price" },
      ],
      styleObject: {
        width: "700px",
      },
      data: [],
      lineItems: null,
    };
  },
  computed: {
    ...mapGetters({
      chosenLoad: "load/getChosenLoadDetailsView",
    }),
  },
  async created() {
    const carriersData = await this.$store.dispatch(
      "carriers/getAllOptionsCarriers",
      this.$route.params.loadId
    );
    this.data = carriersData.carriers;
  },
  watch: {
    'chosenLoad.line_items': {
      immediate: true,
      deep: true,
      handler() {
        this.lineItems = this.chosenLoad.line_items;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-carriers-quote-collapse {
  border: none;
}
.carriers-not-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 150px;
  &__icon {
    font-size: 2rem;
  }
  &__text {
    font-size: 1.1rem;
  }
}
.carriers-quotes-container {
  padding: 1% 0%;
  background-color: $color-background;
  color: $color-primary-company;
  font-family: $font-family-portal;
}
.table-header {
  width: 100%;
}

.carriers-quotes-content {
  width: 100%;
}
::v-deep {
  .el-collapse-item__wrap {
    border: none;
  }
  .table-header-titles {
    justify-content: center;
    margin-left: 0px;
  }
  .el-collapse-item__header {
    background-color: $color-background;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-weight: bold;
    border: none;
  }
  .el-collapse-item__content {
    background-color: $color-background;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    padding-bottom: 1%;
  }
  .el-collapse-item__wrap {
    width: 100%;
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
}
</style>
