<template>
  <el-collapse class="info-accessorials-collapse" v-model="openedTabs">
    <el-collapse-item :title="$t('myLoads.myLoadsDetail.accessorials')" name="accessorials">
      <div class="accessorials-container" v-if="!notFound">
        <div class="accessorials-group" v-if="accessorialsSelected.general.length">
          <div class="accessorials-group__title-container">
            <h6 class="accessorials-group__title" v-if="accessorialsSelected.general.length">
              {{ $t("allOptions.general") }}
            </h6>
          </div>
          <div class="accessorials-group__container-accessorial">
            <div
              v-for="(item, index) in accessorialsSelected.general"
              class="accessorials-group__accessorial-type"
              :key="index"
            >
              <span class="disc-style"> </span>
              <span class="accessorials-group__text">{{ setAccessorialName(item) }}</span>
            </div>
          </div>
        </div>
        <div class="accessorials-group" v-if="accessorialsSelected.pickup.length">
          <div class="accessorials-group__title-container">
            <h6 class="accessorials-group__title" v-if="accessorialsSelected.pickup.length">
              {{ $t("allOptions.origin") }}
            </h6>
          </div>
          <div class="accessorials-group__container-accessorial">
            <div
              v-for="(item, index) in accessorialsSelected.pickup"
              class="accessorials-group__accessorial-type"
              :key="index"
            >
              <span class="disc-style"> </span>
              <span class="accessorials-group__text">{{ setAccessorialName(item) }}</span>
            </div>
          </div>
        </div>
        <div class="accessorials-group" v-if="accessorialsSelected.delivery.length">
          <div class="accessorials-group__title-container">
            <h6 class="accessorials-group__title" v-if="accessorialsSelected.delivery.length">
              {{ $t("allOptions.destination") }}
            </h6>
          </div>
          <div class="accessorials-group__container-accessorial">
            <div
              v-for="(item, index) in accessorialsSelected.delivery"
              class="accessorials-group__accessorial-type"
              :key="index"
            >
              <span class="disc-style"> </span>
              <span class="accessorials-group__text">{{
                item.tms_code
                  ? setAccessorialName(item)
                  : item.name.charAt(0).toUpperCase() + item.name.slice(1)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="accessorials-group__not-found" v-if="notFound">
        <p>{{ $t("myLoads.myLoadsDetail.noAccessoriesFound") }}</p>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "InfoAccessorials",
  props: {
    loadById: Object,
  },
  data() {
    return {
      accessorialsSelected: {
        general: [],
        pickup: [],
        delivery: [],
      },
      accessorials: null,
      openedTabs: ["accessorials"],
      notFound: false,
    };
  },
  async created() {
    this.accessorials = this.loadById.accessorials;
    if (this.accessorials.length === 0) {
      this.notFound = true;
    } else {
      this.notFound = false;
      this.filterAccessorialsByType();
    }
  },
  methods: {
    setAccessorialName(accessorial) {
      if (accessorial.tms_code && accessorial.tms_code.accessorialName) {
        return (
          accessorial.tms_code.accessorialName.charAt(0).toUpperCase() +
          accessorial.tms_code.accessorialName.slice(1)
        );
      }
      return accessorial.name.charAt(0).toUpperCase() + accessorial.name.slice(1);
    },
    filterAccessorialsByType() {
      for (let i = 0; i < this.accessorials.length; i += 1) {
        // Validate accessorials structure
        const isAccessorials =
          "accessorials" in this.accessorials
            ? this.accessorials[i].accessorials
            : this.accessorials[i];
        switch (isAccessorials.accessorial_type) {
          case "general":
            this.accessorialsSelected.general.push(this.accessorials[i]);
            break;
          case "delivery":
            this.accessorialsSelected.delivery.push(this.accessorials[i]);
            break;
          default:
            this.accessorialsSelected.pickup.push(this.accessorials[i]);
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-accessorials-collapse {
  border: none;
}
.accessorials-container {
  padding: 1% 0;
  background-color: $color-gray-light;
  color: $color-primary-company;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.accessorials-group {
  &__title-container {
    color: $color-border-container;
    text-align: start;
    margin-left: 15px;
  }
  &__container-accessorial {
    padding-top: 2%;
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
  }
  &__accessorial-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
  }
  &__text {
    margin-left: 6px;
    text-align: left;
  }
  &__title {
    font-weight: bold;
    margin: 15px 0px;
  }
  &__not-found {
    color: $color-primary-company;
  }
}

.disc-style {
  background-color: $color-primary-company;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

// CSS for the scroll
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $color-gray-light;
  border: 2.1px solid $color-gray-dark;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-gray-dark;
}
::-webkit-scrollbar-corner {
  background-color: $color-gray-light;
}

::v-deep {
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__header {
    background-color: $color-background;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-weight: bold;
    margin: 2% 0 0 0;
    border: none;
  }
  .el-collapse-item__content {
    background-color: $color-background;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    padding-bottom: 1%;
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
}
</style>
