<template>
  <el-collapse class="info-accessorials-collapse" v-model="openedTabs">
    <el-collapse-item
      :title="$t('myLoads.myLoadsDetail.accessorials')"
      name="accessorials"
    >
    <AccessorialsByType
    v-if="accessorials"
    :accessorials="accessorials"
    />
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import AccessorialsByType from "../../../components/AccessorialsByType.vue";

export default {
  name: "InfoAccessorialsResponsive",
  props: {
    loadById: Object,
  },
  components: {
    AccessorialsByType,
  },
  data() {
    return {
      accessorials: null,
      openedTabs: ["accessorials"],
    };
  },
  async created() {
    this.accessorials = this.loadById.accessorials;
  },

};
</script>

<style lang="scss" scoped>
.info-accessorials-collapse {
  border: none;
}
::v-deep {
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__header {
    background-color: $color-background;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding: 0% 7%;
    margin: 0% 0 0 0%;
    border: none;
  }
  .el-collapse-item__content {
    background-color: $color-background;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    padding-bottom: 0%;
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
}
</style>
