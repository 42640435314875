<template>
  <div class="my-loads-details">
    <div class="my-loads-details__back" @click="changeView('MyLoads')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("myLoads.myLoadsDetail.goBack") }}</span>
    </div>
    <!-- <div class="my-loads-details__title" v-if="screenWidth && screenWidth < 1100">
      <span>{{ $t("myLoads.myLoads") }}</span>
    </div> -->
    <b-overlay :show="loading" rounded="sm">
      <div
        class="my-loads-details__card"
        :class="noAccessPermissions ? 'no-access-permissions' : ''"
      >
        <div class="" v-if="!loading">
          <LoadIdInfo
            v-if="loadById"
            :loadInformation="myloadsInformation"
            :isMagayaAccount="isMagayaAccount"
            :loadById="loadById"
            :cancellationStatus="cancellationStatus"
          />
          <hr class="divider" />
          <div class="my-loads-details-content">
            <div class="my-loads-details-content_information">
              <LoadInformation
                v-if="loadById"
                :loadById="loadById"
                @handleChangePoNumber="handleChangePoNumber"
                :showCost="showCost"
              />
              <template v-if="!tokenInfo.is_branded">
                <ClaimOrDispute
                  v-if="infoClaimOrDisp && loadById"
                  :infoClaimOrDisp="infoClaimOrDisp"
                  :loadById="loadById"
                  :key="remountClaimsAndDisputes"
                  @claimOrDisputeCreated="refreshClaimAndDispute"
                />
              </template>
              <div class="my-loads-details-accessorials">
                <InfoAccessorials v-if="loadById && screenWidth > 800" :loadById="loadById" />
                <InfoAccessorialsResponsive
                  v-else-if="loadById && screenWidth <= 800"
                  :loadById="loadById"
                />
              </div>
              <div class="row-lineitems" v-if="!loading">
                <InfoLineItems v-if="loadById && screenWidth > 800" :loadById="loadById" />
                <InfoLineItemsResponsive
                  v-else-if="loadById && screenWidth <= 800"
                  :loadById="loadById"
                />
              </div>
            </div>
            <div class="my-loads-details-content_documents">
              <LoadDocuments
                :documents="documents"
                :changePoNumber="changePoNumber"
                :generateDocsLoading="generateDocsLoading"
                @handleLoading="handleLoading"
                @triggerGetDocuments="triggerGetDocuments"
                @handleChangePoNumber="handleChangePoNumber"
                :chosenLoad="loadById"
                ref="load-documents"
                :key="remountDocumentsViewer"
              />
              <PayButton
                v-if="
                  loadById.load_status.toLowerCase() === 'completed' &&
                  !tokenInfo.is_branded &&
                  !isMelioDataEmpty
                "
                :melioData="melioData"
              />
            </div>
          </div>
          <div class="row-carriers-quoted" v-if="!loading">
            <InfoCarriesQuote v-if="loadById && screenWidth > 800" />
            <InfoCarriesQuoteResponsive v-else-if="screenWidth <= 800" />
          </div>
          <div class="row-history">
            <InfoHistory :loadById="loadById" />
          </div>
        </div>
        <LoadDetailSkeleton v-else-if="screenWidth > 800" />
        <LoadDetailSkeletonResponsive v-else-if="screenWidth <= 800" />
      </div>
      <template #overlay>
        <div class="loading" v-show="loading">
          <b-icon icon="truck" font-scale="3" animation="cylon" class="loading__text"></b-icon>
          <p class="loading__text">{{ $t("loading") }}...</p>
        </div>
      </template>
    </b-overlay>
    <b-modal
      centered
      size="lg"
      v-model="searchingTruckModal"
      ref="truck-modal"
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <SearchingTruckModal @closeModal="closeModal" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadStatusType from "@/Extend/LoadStatusType";
import InfoHistory from "@/components/InfoHistory.vue";
import LoadDetailSkeleton from "./components/LoadDetailSkeleton.vue";
import LoadDetailSkeletonResponsive from "./components/LoadDetailSkeletonResponsive.vue";
import LoadIdInfo from "./components/LoadIdInfo.vue";
import LoadInformation from "./components/LoadInformation.vue";
import ClaimOrDispute from "./components/ClaimOrDispute.vue";
import LoadDocuments from "./components/documents/LoadDocuments.vue";
import InfoLineItems from "./components/InfoLineItems.vue";
import InfoCarriesQuote from "./components/InfoCarriesQuote.vue";
import InfoCarriesQuoteResponsive from "./components/InfoCarriersQuoteResponsive.vue";
import InfoLineItemsResponsive from "./components/InfoLineItemsResponsive.vue";
import InfoAccessorials from "./components/InfoAccessorials.vue";
import InfoAccessorialsResponsive from "./components/InfoAccessorialsResponsive.vue";
import onReSize from "../../Extend/onResize";
import SearchingTruckModal from "./components/SearchingTruckModal.vue";
import PayButton from "./components/PayButton.vue";

export default {
  name: "LoadsDetails",
  mixins: [onReSize, LoadStatusType],
  components: {
    LoadIdInfo,
    LoadInformation,
    ClaimOrDispute,
    LoadDocuments,
    InfoLineItems,
    InfoCarriesQuote,
    InfoCarriesQuoteResponsive,
    InfoLineItemsResponsive,
    InfoAccessorials,
    InfoAccessorialsResponsive,
    InfoHistory,
    LoadDetailSkeleton,
    LoadDetailSkeletonResponsive,
    SearchingTruckModal,
    PayButton,
  },
  data() {
    return {
      // documents: [],
      loading: false,
      myloadsInformation: {},
      loadById: null,
      selectedLineItems: [],
      lineItemsError: [["", "", "", "", "", "", "", ""]],
      infoClaimOrDisp: [],
      remountDocumentsViewer: 0,
      remountClaimsAndDisputes: 0,
      isMagayaAccount: null,
      changePoNumber: false,
      tokenInfo: null,
      searchingTruckModal: false,
      noAccessPermissions: false,
      showCost: false,
      invoiceDocument: [],
      cancellationStatus: {},
      generateDocsLoading: false,
      melioData: [],
      isMelioDataEmpty: true,
    };
  },
  computed: {
    ...mapGetters({
      tenderSocket: "load/tender/getTenderSocket",
      quoteData: "load/getChosenLoadDetailsView",
      // documents: "load/docsGenerate/getDocument"
    }),
    documents: {
      get() {
        return this.$store.getters["load/docsGenerate/getDocument"];
      },
      set(value) {
        this.$store.commit("load/docsGenerate/setDocument", value);
      },
    },
  },
  watch: {
    tenderSocket() {
      if (!this.tenderSocket) {
        return;
      }
      const socketValue = this.tenderSocket?.data?.data;
      if (this.tenderSocket.notification === "portal-magaya-terminated") {
        this.loadById.magaya_record_url = socketValue || "";
      }
      if (this.tenderSocket.notification === "portal-tender-terminated") {
        this.loadById.pickup_carrier_number = socketValue || "";
      }
      this.$store.commit("load/tender/disconnectSocket");
    },
    quoteData: {
      deep: true,
      handler() {
        this.loadById = this.quoteData;
      },
    },
  },
  async created() {
    this.loading = true;
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.showCost = this.tokenInfo.show_cost;
    this.isMagayaAccount = this.tokenInfo.magaya_guid;
    this.$store.commit("load/docsGenerate/addInvoiceDocument", []);
    const promises = [
      this.$store.dispatch("load/getLoadByIdLoadDetailsView", this.$route.params.loadId),
      this.getClaimAndDisputeByLoad(),
      this.$store.dispatch("load/docsGenerate/getDocumentById", this.$route.params.loadId),
      this.$store.dispatch("cancellation/getCancellationStatus", this.$route.params.loadId),
    ];
    await Promise.all(promises).then((values) => {
      [this.loadById, this.infoClaimOrDisp, this.documents, this.cancellationStatus] = values;
    });
    const loadStatus = this.identifyLoadStatus();
    if (loadStatus === "delivered" && !this.tokenInfo.is_branded) {
      await this.requestInvoiceDocument();
    }
    this.showSearchingTruckModal();
    document.title = `${this.loadById.name} | Portal`;
    if (loadStatus === "quoted") {
      this.$router.push({
        name: "CarriersInfo",
        params: { loadId: this.$route.params.loadId },
      });
      return;
    }
    this.$store.commit("claims/setInfoClaimOrDispute", this.infoClaimOrDisp);
    for (let i = 0; i < this.loadById.line_items.length; i += 1) {
      this.selectedLineItems.push(this.loadById.line_items[i]);
    }

    /// Get information for loads  \\\
    this.myloadsInformation = {
      id: this.loadById.id,
      quoteTotal: this.loadById.quote_total,
      dispacher: this.loadById.dispacher_internal,
      mode: this.loadById.name,
      carrier: this.loadById.carrier_name,
      poNumber: this.loadById.po_number,
      proNumber: this.loadById.pro_number,
      billingReference: this.loadById.billing_reference,
      cargoSummary: this.loadById.cargo_summary,
      origin: this.loadById.origin,
      carrierInfo: this.loadById.carrier,
      loadStatus: this.loadById.load_status,
    };
    this.loading = false;
    /// Get melio status  \\\
    if (
      this.loadById.load_status.toLowerCase() === "completed" ||
      this.loadById.load_status.toLowerCase() === "delivered"
    ) {
      await this.getMelioStatus();
    }
  },
  beforeDestroy() {
    this.$store.commit("load/clearLoadDetailInfo");
    this.$store.commit("cancellation/setCancellationStatus", false);
    this.$store.commit("cancellation/setCurrentCancellationReason", null);
  },
  methods: {
    async requestInvoiceDocument() {
      return this.$store.dispatch(
        "load/docsGenerate/getInvoiceDocByLoadId",
        this.$route.params.loadId
      );
    },
    getClaimAndDisputeByLoad() {
      if (this.$options.filters.checkPermission("get:claims")) {
        return this.$store.dispatch("claims/getClaimsById", this.$route.params.loadId);
      }
      return null;
    },
    async getDocumentsById() {
      const response = await this.$store.dispatch(
        "load/docsGenerate/getDocumentById",
        this.$route.params.loadId
      );
      this.documents = response;
    },
    async refreshClaimAndDispute() {
      this.infoClaimOrDisp = await this.getClaimAndDisputeByLoad();
      this.$store.commit("claims/setInfoClaimOrDispute", this.infoClaimOrDisp);
      this.remountClaimsAndDisputes += 1;
    },
    async triggerGetDocuments() {
      await this.getDocumentsById();
      this.remountDocumentsViewer += 1;
      this.handleLoading(false);
    },
    handleLoading(data) {
      this.generateDocsLoading = data;
    },
    identifyLoadStatus() {
      const loadStatus = this.setLoadStatusColor(this.loadById.load_status);
      return loadStatus.label.toLowerCase();
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
    handleChangePoNumber(data) {
      this.changePoNumber = data;
    },
    closeModal() {
      this.searchingTruckModal = false;
    },
    showSearchingTruckModal() {
      if (
        this.loadById.sales_status === "Won" &&
        this.loadById.load_status.toLowerCase() === "pending"
      ) {
        this.searchingTruckModal = true;
        this.noAccessPermissions = true;
      }
    },
    async getMelioStatus() {
      try {
        const response = await this.$store.dispatch(
          "paymentHub/getMelioPayStatus",
          this.$route.params.loadId
        );
        if (response && response.status === 200) {
          this.melioData = response.data;
          if (this.melioData.length > 0) {
            this.isMelioDataEmpty = false;
          }
        }
      } catch {
        this.melioData = [];
        this.isMelioDataEmpty = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Modal.scss";

.my-loads-details {
  width: 97%;
  font-family: $font-family-portal;
  margin: 0px auto;
  margin-top: 2rem;
  padding-bottom: 20px;
  &__title {
    @include title-views(1.75rem);
  }
  &__card {
    color: $color-primary-company;
    border: 1px solid $color-border-container;
    border-radius: 15px;
    background-color: $color-background;
    padding: 3% 4% 1% 3%;
  }
  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: fit-content;
    margin-bottom: 20px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}
.divider {
  border-bottom: 2px solid $color-border-container;
}
.my-loads-details-content {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.button-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.button-save-line {
  border-radius: 15px;
  margin-right: 10px;
  padding: 0px 20px;
  border: none;
  background-color: $color-border-container;
  color: $color-white;
  height: 40px;
  font-weight: bold;
  font-family: $font-family-portal;
  @include font-small-standart-text;
  &:hover {
    background: $color-primary-trigger-button-hover;
  }
}
.row-lineitems {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0px;
}
.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

.no-access-permissions {
  pointer-events: none;
}

@media (max-width: 800px) {
  .my-loads-details-content {
    display: flex;
    flex-direction: column;
  }
}

::v-deep {
  .modal-content {
    padding: 2% 6%;
  }
  .bg-light {
    border-radius: 15px !important;
    backdrop-filter: 0px;
  }
}
</style>
